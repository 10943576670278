<template>
  <div v-if="render">
    <table id="subject-table" class="table table-bordered table-striped" style="width:100%">
      <caption>Table 5: Count and Percentage of Teachers without/with NBC Status with Student Value-Added Growth Scores</caption>
      <thead>
        <tr>
          <th rowspan="2">
            Rating for All
          </th>
          <th class="text-center" colspan="3">
            NBC Status - Has Certification
          </th>
        </tr>
        <tr>
          <th class="text-right">
            No
          </th>
          <th class="text-right">
            Yes
          </th>
          <th class="text-right">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Not high impact</th>
          <td class="text-right">
            {{ tableData.filter(r => r.rating == 1).filter(r => !r.isNBC).length.toLocaleString("en-US") }}<br>
            {{ ((tableData.filter(r => r.rating == 1).filter(r => !r.isNBC).length / tableData.length * 100.0) || 0).toFixed(2) }}%
          </td>
          <td class="text-right">
            {{ tableData.filter(r => r.rating == 1).filter(r => r.isNBC).length.toLocaleString("en-US") }}<br>
            {{ ((tableData.filter(r => r.rating == 1).filter(r => r.isNBC).length / tableData.length * 100.0) || 0).toFixed(2) }}%
          </td>
          <td class="text-right">
            {{ tableData.filter(r => r.rating == 1).length.toLocaleString("en-US") }}<br>
            {{ ((tableData.filter(r => r.rating == 1).length / tableData.length * 100.0) || 0).toFixed(2) }}%
          </td>
        </tr>
        <tr>
          <th>High impact</th>
          <td class="text-right">
            {{ tableData.filter(r => r.rating == 2).filter(r => !r.isNBC).length.toLocaleString("en-US") }}<br>
            {{ ((tableData.filter(r => r.rating == 2).filter(r => !r.isNBC).length / tableData.length * 100.0) || 0).toFixed(2) }}%
          </td>
          <td class="text-right">
            {{ tableData.filter(r => r.rating == 2).filter(r => r.isNBC).length.toLocaleString("en-US") }}<br>
            {{ ((tableData.filter(r => r.rating == 2).filter(r => r.isNBC).length / tableData.length * 100.0) || 0).toFixed(2) }}%
          </td>
          <td class="text-right">
            {{ tableData.filter(r => r.rating == 2).length.toLocaleString("en-US") }}<br>
            {{ ((tableData.filter(r => r.rating == 2).length / tableData.length * 100.0) || 0).toFixed(2) }}%
          </td>
        </tr>
        <tr>
          <th>Total</th>
          <td class="text-right">
            {{ tableData.filter(r => !r.isNBC).length.toLocaleString("en-US") }}<br>
            {{ ((tableData.filter(r => !r.isNBC).length / tableData.length * 100.0) || 0).toFixed(2) }}%
          </td>
          <td class="text-right">
            {{ tableData.filter(r => r.isNBC).length.toLocaleString("en-US") }}<br>
            {{ ((tableData.filter(r => r.isNBC).length / tableData.length * 100.0) || 0).toFixed(2) }}%
          </td>
          <td class="text-right">
            {{ tableData.length.toLocaleString("en-US") }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { subjectCase } from '../helpers.js'

export default {
  data() {
    return {
      subjectCase
    }
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    excelExportData() {  
      //items is an array of objects, each with a property for 'Subject', and NBC status 'No', NBC status 'Yes', and 'Total'
      return {
        name: 'T5-NBC-Category',
        items: [
          {  
            'Rating for All': 'Not high impact',
            'Has NBC Certification': this.tableData.filter(r => r.rating == 1).filter(r => !r.isNBC).length,
            '% Has NBC Certification': this.tableData.filter(r => r.rating == 1).filter(r => !r.isNBC).length / this.tableData.length * 100.0,
            'No NBC Certification': this.tableData.filter(r => r.rating == 1).filter(r => r.isNBC).length,
            '% No NBC Certification': this.tableData.filter(r => r.rating == 1).filter(r => r.isNBC).length / this.tableData.length * 100.0,
          },
          {  
            'Rating for All': 'High impact',
            'Has NBC Certification': this.tableData.filter(r => r.rating == 2).filter(r => !r.isNBC).length,
            '% Has NBC Certification': this.tableData.filter(r => r.rating == 2).filter(r => !r.isNBC).length / this.tableData.length * 100.0,
            'No NBC Certification': this.tableData.filter(r => r.rating == 2).filter(r => r.isNBC).length,
            '% No NBC Certification': this.tableData.filter(r => r.rating == 2).filter(r => r.isNBC).length / this.tableData.length * 100.0,
          },
          {  
            'Rating for All': 'Total',
            'Has NBC Certification': this.tableData.filter(r => !r.isNBC).length,
            '% Has NBC Certification': this.tableData.filter(r => !r.isNBC).length / this.tableData.length * 100.0,
            'No NBC Certification': this.tableData.filter(r => r.isNBC).length,
            '% No NBC Certification': this.tableData.filter(r => r.isNBC).length / this.tableData.length * 100.0,
          }
        ]
      }
    },
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubject']),
    tableData() {
      return this.detailsBySubject[undefined]
    }
  }
}
</script>