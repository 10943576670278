<template>
  <div v-if="render" class="page-break-before">
    <highcharts class="chart" :constructor-type="'mapChart'" :options="chartOptions" style="page-break-inside: avoid; break-inside: avoid;" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import geojson from './2018districts_simplified.json'

// const leaData = [
//   ['0101000', 7],
//   ['0401000', 10]
// ]

export default {
  props: {
    render: {
      type: Boolean,
      default: true
    },
    figure: {
      type: Number,
      required: true
    }
  },
  methods: {
    // excelExportData() {
    //   return {
    //     name: `chart`, //TODO: give proper name
    //     items: []
    //   }
    // }
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['districtSummaries']),
    
    chartOptions() {
      return {
        chart: {
          height: 800,
          plotBackgroundColor: '#FFFFFF',
          plotShadow: false,
          spacing: [40, 30, 45, 30],
          backgroundColor: 'transparent',
          map: geojson,
          type: 'map',
        },
        title: {
          text: ''
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'bottom'
          }
        },
        colorAxis: {
          min: 0,
          tickPixelInterval: 100
        },
        credits: {
          enabled: false
        },
        series: [{
          data: this.districtSummaries,
          keys: ['lea', 'value'],
          joinBy: 'lea',
          name: 'Percentage of High Impact Teachers',
          dataLabels: {
            enabled: true,
            format: '{point.properties.Name}'
          },
          tooltip: {
            pointFormat:'<span style="font-weight: bold;">{point.properties.Name}</span>: <b>{point.value}%</b>'
          }
        }]
      }
    }
  },
  data() {
    return {
    }
  }
}

</script>